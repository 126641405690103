.flatpickr-current-month
  font-weight: 400 // Safari change font to cursive in case of font-weight: 300

  .flatpickr-monthDropdown-months
    -webkit-appearance: none // remove Safari's dropdown style
    padding-right: 25px
    background: url('data:image/svg+xml;utf8,<svg fill="black" height="24" viewBox="0 0 24 24" width="24" xmlns="http://www.w3.org/2000/svg"><path d="M7 10l5 5 5-5z"/></svg>') no-repeat right -4px center
    line-height: 22px
    font-weight: 400
    &:hover
      background: url('data:image/svg+xml;utf8,<svg fill="black" height="24" viewBox="0 0 24 24" width="24" xmlns="http://www.w3.org/2000/svg"><path d="M7 10l5 5 5-5z"/></svg>') no-repeat right -4px center
      background-color: rgba(0, 0, 0, 0.05)
